import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import config from '../../config';
import UserForm from './components/UserForm';

function Profile() {
  const params = useParams();
  const auth = useSelector(state => state.auth);
  const [user, setUser] = useState({
    _id: null,
    username: '',
    firstname: '',
    lastname: '',
    mobile: ''
  })

  useEffect(() => {
    if(params.id && auth.user) {
      fetch(`${config.SERVER_URL}/users/${params.id}`, {
        method: 'GET',
        headers: {
          "Authorization":"bearer " + auth.user.token,
          "Accept":"application/json"
        },
      })
      .then(res => res.json())
      .then(res => {
        setUser({...res})
      })
      .catch(err => {
        
      })
    }
  }, [params, auth])

  return (
    <div className={'container mt-3'}>
      <div className={'card'}>
        <div className={'card-header'}>
          <h4>{user.email}</h4>
        </div>
        <div className={'card-body'}>
          <UserForm
            user={user}
            setUser={setUser}
          />
        </div>
      </div>
    </div>
  )
}

export default Profile;
import React from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route, Redirect, NavLink, Link} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import Login from './pages/Login/Login';
import UserList from './pages/Users/UserList';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './pages/Dashboard/Dashboard';
import { authActions } from './store/action/auth.actions';
import PharmacyList from './pages/Pharmacies/PharmacyList';
import Pharmacy from './pages/Pharmacies/Pharmacy';
import Profile from './pages/Users/Profile';

function App() {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  function logout() {
    dispatch(authActions.logout())  
  }

  return (
    <div className='App'>
      <Router>
        <nav className={'navbar navbar-expand-lg navbar-light bg-light sticky-top'}>
          <Link to={auth.isLoggedIn ? '/dashboard' : '/login'} className={'navbar-brand flex-row'}>
            <img src={require('./logo.png')} style={{height: 50}} alt='logo'/>
          </Link>
          
          <ul className={'navbar-nav mr-auto'}>
            {auth.isLoggedIn && (
              <>
								<NavLink to={'/dashboard'} className={'nav-item nav-link'} activeClassName={'active'}>Dashboard</NavLink>
                <NavLink to={'/users'} className={'nav-item nav-link'} activeClassName={'active'}>Users</NavLink>
                <NavLink to={'/pharmacies'} className={'nav-item nav-link'} activeClassName={'active'}>Pharmacies</NavLink>
              </>
            )}      
          </ul>
          <form className={'form-inline my-2 my-lg-0'}>
            {auth.isLoggedIn && (
              <button className={'btn btn-outline-primary my-2 my-sm-0'} onClick={() => logout()}>Logout</button>
            )}  
          </form>
        </nav>
      
        <Switch>
          <Route path='/login' component={Login} />
          <PrivateRoute path={'/dashboard'} component={Dashboard} />
          <PrivateRoute exact path='/users' component={UserList} />
          <PrivateRoute path='/users/:id' component={Profile} />
          <PrivateRoute exact path='/pharmacies' component={PharmacyList} />
          <PrivateRoute path='/addpharmacy' component={Pharmacy} />
          <PrivateRoute path='/pharmacies/:id' component={Pharmacy} />
          <Redirect to={'/login'}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;

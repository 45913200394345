/* eslint-disable array-callback-return */
/* eslint-disable no-undef */
import {createReducer} from '@reduxjs/toolkit';
import { getPharmacy, addPharmacy, updatePharmacy, setPharmacy } from '../action/pharmacy.actions';
import pharmacyErrorValidation from '../validators/pharmacyErrorValidation';

const initial_state = {
  pharmacy: {
    name: '',
    address: '',
    address2: '',
    city: '',
    postalCode: '',
    province: '',
    phone: '',
    latitude: null,
    longitude: null
  },
  canSubmit: false,
	sending: false,
	sendError: null,
	sendSuccess: false,
	errors: {},
}

const validatePharmacy = pharmacy => {
	let isValid = true;
	//address2 not required, medications/allergies can be blank
	Object.keys(pharmacy).map((key) => {
		if(key === 'phone') return;
    if(key === 'postalCode') return;
    if(key === 'address2') return;
    if(Object.keys(initial_state.pharmacy).includes(pharmacy[key]) && pharmacy[key].length < 1){
			isValid = false;
		}
  });

	return isValid;
};

export const pharmacyReducer = createReducer(initial_state, {
  [setPharmacy]: (state, action) => {
    let isValid = validatePharmacy(action.payload)
    let errors = pharmacyErrorValidation(action.payload)

    return {
      ...state,
      pharmacy: action.payload,
      errors: {
        ...errors
      },
			canSubmit: Object.keys(errors).length < 1 && isValid
    }
  },
  [getPharmacy.pending]: (state) => {
    return {
      ...state,
      sending: true,
      sendError: null,
    }
  },
  [getPharmacy.fulfilled]: (state, action) => {
    return {
      ...state,
      sending: false,
      pharmacy: {
        ...action.payload
      },
      sendError: null,
      errors: {},
      canSubmit: true
    }
  },
  [getPharmacy.rejected]: (state, action) => {
    return {
      ...state,
      sending: false,
      sendError: action.error.message
    }
  },
  [addPharmacy.pending]: (state) => {
    return {
      ...state,
      sending: true,
      sendError: null
    }
  },
  [addPharmacy.fulfilled]: (state, action) => {
    return {
      ...state,
      sending: false,
      pharmacy: {
        ...action.payload
      },
      sendError: null
    }
  },
  [addPharmacy.rejected]: (state, action) => {
    return {
      ...state,
      sending: false,
      sendError: action.error.message
    }
  },
  [updatePharmacy.pending]: (state) => {
    return {
      ...state,
      sending: true,
      sendError: null
    }
  },
  [updatePharmacy.fulfilled]: (state, action) => {
    return {
      ...state,
      sending: false,
      pharmacy: {
        ...action.payload
      },
      sendError: null
    }
  },
  [updatePharmacy.rejected]: (state, action) => {
    return {
      ...state,
      sending: false,
      sendError: action.error.message
    }
  }
})
import {createAction, createAsyncThunk} from '@reduxjs/toolkit';
import config from '../../config';
import {pharmacyConstants} from '../constants/pharmacy.constants';

export const setPharmacy = createAction(pharmacyConstants.SET_PHARMACY);
export const clearPharmacy = createAction(pharmacyConstants.CLEAR_PHARMACY);
export const getPharmacy = createAsyncThunk(pharmacyConstants.GET_PHARMACY_REQUEST, 
	async (pharmacy_id, thunkAPI) => {
    const { auth } = thunkAPI.getState()

		const options = {
			method: 'GET',
			headers: {
        "Authorization":"bearer " + auth.user.token,
				'Content-Type':'application/json',
			},
		};

		try {
			return new Promise((resolve, reject) => {
        fetch(`${config.SERVER_URL}/pharmacies/${pharmacy_id}`, options)
          .then(handleResponse)
					.then(res => resolve(res))
					.catch(err => reject(new Error(err)));
			});
		} catch (err) {
			return thunkAPI.rejectWithValue(err);
		}		
	}
);

export const addPharmacy = createAsyncThunk(pharmacyConstants.ADD_PHARMACY_REQUEST, 
	async (_, thunkAPI) => {
    const {auth, pharmacy} = thunkAPI.getState()

		const options = {
			method: 'POST',
			headers: {
        "Authorization":"bearer " + auth.user.token,
				'Content-Type':'application/json',
			},
			body: JSON.stringify(pharmacy.pharmacy)
		};

		try {
			return new Promise((resolve, reject) => {
        fetch(`${config.SERVER_URL}/pharmacies`, options)
          .then(handleResponse)
					.then(res => resolve(res))
					.catch(err => reject(new Error(err)));
			});
		} catch (err) {
			return thunkAPI.rejectWithValue(err);
		}		
	}
);

export const updatePharmacy = createAsyncThunk(pharmacyConstants.UPDATE_PHARMACY_REQUEST, 
	async (id, thunkAPI) => {
		const {auth, pharmacy} = thunkAPI.getState();

		const options = {
			method: 'PUT',
			headers: {
        "Authorization":"bearer " + auth.user.token,
				'Content-Type':'application/json',
			},
			body: JSON.stringify(pharmacy.pharmacy)
		};

		try {
			return new Promise((resolve, reject) => {
        fetch(`${config.SERVER_URL}/pharmacies/${id}`, options)
          .then(handleResponse)
					.then(res => {
            console.log(res)
            resolve(res)
          })
					.catch(err => reject(new Error(err)));
			});
		} catch (err) {
			return thunkAPI.rejectWithValue(err);
		}		
	}
);

export const deletePharmacy = createAsyncThunk(pharmacyConstants.DELETE_PHARMACY_REQUEST, 
	async (pharmacy_id, thunkAPI) => {
    const {auth} = thunkAPI.getState()
    
		const options = {
			method: 'DELETE',
			headers: {
        "Authorization":"bearer " + auth.user.token,
				'Content-Type':'application/json',
			}
		};

		try {
			return new Promise((resolve, reject) => {
        fetch(`${config.SERVER_URL}/pharmacies/${pharmacy_id}`, options)
          .then(handleResponse)
					.then(res => resolve(res))
					.catch(err => reject(new Error(err)));
			});
		} catch (err) {
			return thunkAPI.rejectWithValue(err);
		}		
	}
);

const handleResponse = response => {
  if(response.ok){
    return response.json()
  }

  throw Error(`Code: ${response.status}`)
}
export default function pharmacyErrorValidation(values) {
  let errors = {}

  if(values.name && values.name.length < 1){
    errors.name = 'Pharmacy name must not be blank'
  } else {
    delete errors.name
  }

  if(values.address && values.address.length < 1){
    errors.address = 'Pharmacy address is mandatory'
  } else {
    delete errors.address
  }

  if(!values.latitude) {
    errors.latitude = 'Latitude is mandatory'
  } else {
    delete errors.latitude
  }

  if(!values.longitude) {
    errors.longitude = 'Longitude is mandatory'
  } else {
    delete errors.latitude
  }

  return errors;
}
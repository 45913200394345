import React from 'react';
import './ToggleSwitch.css';

const ToggleSwitch = ({ name, value, setValue, yesNo, green }) => {
  return (
    <div className="toggle-switch" onClick={() => setValue(!value)}>
      <input
        type="checkbox" 
        className="toggle-switch-checkbox" 
        name={name}
        id={name}
        checked={value}
        disabled
      />
      <label className="toggle-switch-label" htmlFor={name}>
        <span className={`toggle-switch-inner ${yesNo && 'yesNo'} ${green && 'green'}`}></span>
        <span className="toggle-switch-switch"></span>
      </label>
    </div>
  )
}

export default ToggleSwitch
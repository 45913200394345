import {authConstants} from '../constants/auth.constants';
import config from '../../config';

export const authActions = {
  login,
  //register,
	logout,
}

// function register(username, password) {
//   return dispatch => {
//     dispatch(request());
//     return authAPI.registerUser(username, password)
//       .then(user => {
//         dispatch(success(user));
//       })
//       .catch(error => {
//         dispatch(failure(error));
//       })
//   }

//   function request(){ return {type: authConstants.REQUEST_LOGIN}};
//   function success(user){ return {type: authConstants.LOGIN_SUCCESS, user}}
//   function failure(error){ return {type: authConstants.LOGIN_FAILURE, error}}
// }

function login(username, password) {
  return dispatch => {
    dispatch(requestLogin())
    
    return fetch(`${config.SERVER_URL}/auth/login`, {
      method: 'POST',
      headers: {
        "Content-Type":"application/json",
      },
      body: JSON.stringify({
        email: username, password: password
      })
    })
      .then(handleResponse)
      .then(
        user => {
          dispatch(success(user));
        },
        error => {
          console.log(error)
          dispatch(failure(error));
        }
      );
  }

  function requestLogin(){ return {type: authConstants.REQUEST_LOGIN}};
  function success(user){ return {type: authConstants.LOGIN_SUCCESS, user}}
  function failure(error){ return {type: authConstants.LOGIN_FAILURE, error: error.toString()}}
}

function handleResponse(response){
  if (!response.ok) {
    return response.json()
      .catch(() => {
        // Couldn't parse the JSON
        throw new Error(response.status);
      })
      .then(({message}) => {
        // Got valid JSON with error response, use it
        throw new Error(message || response.status);
      });
  }
  // Successful response, parse the JSON and return the data
  return response.json();
}

function logout(){
  return {
    type: authConstants.LOGOUT
  }
}
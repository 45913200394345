import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {NavLink, useHistory} from 'react-router-dom';
import {FaEdit, FaPlus} from 'react-icons/fa';
import config from '../../config';

function PharmacyList(){
  const [pharmacies, setPharmacies] = useState([]);
  const [filteredPharmacies, setFilteredPharmacies] = useState([]);
  const auth = useSelector(state => state.auth);
  const [filter, setFilter] = useState('');
  const history = useHistory();

  useEffect(() => {
    if(!pharmacies.length && auth.user) {
      fetch(`${config.SERVER_URL}/pharmacies`, {
        method: 'GET',
        headers: {
          "Accept":"application/json"
        },
      })
      .then(res => res.json())
      .then(pharmacies => {
        setPharmacies(pharmacies)
        setFilteredPharmacies(pharmacies)
      })
    }
  }, [auth, pharmacies.length])

  useEffect(() => {
    if(filter.length > 0) {
      setFilteredPharmacies([...pharmacies.filter(p => JSON.stringify(p).toLowerCase().includes(filter.toLowerCase()))])
    }
  }, [filter, pharmacies])

  const renderPharmacies = filteredPharmacies.sort((a, b) => a.name - b.name).map((x, i) => {
    return (
      <NavLink key={i.toString()} style={{color: '#333'}} className={'list-group-item list-group-item-action d-flex flex-row text-decoration-none'} to={`/pharmacies/${x._id}`}>
        <span className={'col-sm-11 col-md-1'}><FaEdit color={'#007bff'}/></span>
        <span className={'col-sm-11 col-md-3 text-left'}><strong>{x.name}</strong></span>
        <span className={'col-sm-12 col-md-3 text-left'}>{x.address}</span>
        <span className={'col-sm-12 col-md-3 text-left'}>{x.city}</span>
        <span className={'col-sm-12 col-md-2 text-left'}>{x.phone}</span>
      </NavLink>
    )
  })

  return (
    <div className={'col-sm-12 offset-md-1 col-md-10 mt-3'}>
      <div className={'d-flex flex-row justify-content-start'}>
        <h2 className={'p-1 m-1'}>Pharmacies</h2>
      </div>
      <hr />
      <div className={'d-flex flex-row justify-content-between'}>
        <input className={'form-control col-md-2'} placeholder={'filter list'} value={filter} onChange={event => setFilter(event.target.value)}/>
        <button type={'button'} className={'btn btn-sm btn-outline-primary'} onClick={() => history.push('/addpharmacy')}>
          <FaPlus /> Add
        </button>
      </div>
      <hr />
      <div className={'list-group'}>
        <div className={'list-header col-sm-12 col-md-12'}>
        <span className={'col-sm-12 col-md-1'}>&nbsp;</span>
        <span className={'col-sm-12 col-md-3 text-left'}><strong>Name</strong></span>
        <span className={'col-sm-12 col-md-3 text-left'}><strong>Address</strong></span>
        <span className={'col-sm-12 col-md-3 text-left'}><strong>City</strong></span>
        <span className={'col-sm-12 col-md-2 text-left'}><strong>Phone Number</strong></span>
        </div>
        {renderPharmacies}
      </div>
    </div>
  )
}

export default PharmacyList;
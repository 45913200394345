import React from 'react';
import ToggleSwitch from '../../../components/UI/ToggleSwitch/ToggleSwitch';

const ConfigurableToggleBlock = ({ label, hasMessage, configObject, objectKey, updateConfig }) => (
  <div className="configurableBlock">
    <div className="statusRow">
				<span className='label'>{label}</span>
        <ToggleSwitch
          name={objectKey} 
          value={configObject[objectKey]} 
          setValue={
            (value) => updateConfig({
              [objectKey]: value,
              [`${objectKey}Message`]: null
            })
          }
          green
          yesNo
        />
			</div>
      {hasMessage && !configObject[objectKey] && (
        <div className='statusRow'>
          <span>Message:</span>
          <input 
            className='form-control' 
            value={configObject[`${objectKey}Message`] || ''}
            onChange={
            (e) => updateConfig({
              [`${objectKey}Message`]: e.target.value,
            })
          }/>
			  </div>
      )}
  </div>
)

export default ConfigurableToggleBlock;
import React, { useEffect, useState } from 'react';
import config from '../../../config';

const ServerHealth = () => {
	const [appStatus, setAppStatus] = useState({});
	// health admin server
	useEffect(() => {
		const options = {
			method: 'GET',
			headers: {
				'Content-Type':'application/json',
			},
		};

		fetch(`${config.SERVER_URL}/healthcheck`, options)
					.then(res => res.json())
					.then(data => {
						setAppStatus(status => ({...status, admin_message: data.message}))
					})
					.catch(err => setAppStatus({admin_message: 'offline'}));
	}, [])

	// health check email server
	useEffect(() => {
		const options = {
			method: 'GET',
			headers: {
				'Content-Type':'application/json',
			},
		};

		fetch(`${config.EMAIL_SERVER_URL}/healthcheck`, options)
					.then(res => res.json())
					.then(data => {
						setAppStatus(status => ({...status, email_message: data.message}))
					})
					.catch(err => setAppStatus({email_message: 'offline'}));
	}, [])

	return (
		<div className={'col-sm-12 sectionContainer'}>
			{/* system status */}
			<h4>System Status</h4>
			<div className={'statusRow'}>
				<p>Email Server:</p>
				<p className={appStatus.email_message === 'OK!' ? 'healthy' : 'unhealthy'}>
          {appStatus.email_message === 'OK!' ? 'Healthy' : appStatus.email_message }
        </p>
			</div>
			<div className={'statusRow'}>
				<p>Admin Server:</p>
				<p className={appStatus.admin_message === 'OK!' ? 'healthy' : 'unhealthy'}>
          {appStatus.admin_message === 'OK!' ? 'Healthy' : appStatus.admin_message}
        </p>
			</div>
		</div>
	)
}

export default ServerHealth;
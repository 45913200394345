import {authConstants} from '../constants/auth.constants';

const INITIAL_STATE = {
  user: null,
  loggingIn: false,
  isLoggedIn: false,
  loginError: null
}

export function authReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case authConstants.REQUEST_LOGIN:
      return {
        ...state,
        loggingIn: true,
        loginError: false
      }
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        isLoggedIn: true,
        user: action.user
      }
    case authConstants.LOGIN_FAILURE:
      return {
        ...state,
        loginError: action.error,
        isLoggedIn: false,
        loggingIn: false
			}
		case authConstants.LOGOUT: 
			return {
				...INITIAL_STATE
			}
    default:
      return state;
  }
} 
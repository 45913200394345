import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {NavLink} from 'react-router-dom';
import config from '../../config';

function UserList() {
  const [users, setUsers] = useState([]);
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    if(!users.length && auth.user) {
      fetch(`${config.SERVER_URL}/users`, {
        method: 'GET',
        headers: {
          "Authorization":"bearer " + auth.user.token,
          "Accept":"application/json"
        },
      })
        .then(users => users.json())
        .then(users => {
          setUsers(users)
        })
    }
  }, [auth.user, users.length])
  
  const renderUsers = users.sort((a, b) => a.name - b.name).map((x, i) => {
    return (
      <NavLink key={i.toString()} className={'list-group-item list-group-item-action d-flex flex-row text-decoration-none'} to={`/users/${x._id}`}>
        <span className={'col-sm-12 col-md-6 text-left'}><strong>{x.email}</strong></span>
        <span className={'col-sm-12 col-md-3 text-left'}>{x.firstname}</span>
        <span className={'col-sm-12 col-md-3 text-left'}>{x.lastname}</span>
      </NavLink>
    )
  })
  
  return (
    <div className={'col-sm-12 offset-md-2 col-md-8'}>
      <h2>Users</h2>
      <div className={'list-group'}>
        {renderUsers}
      </div>
    </div>
  )
}

export default UserList;
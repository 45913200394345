/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import './styles.css';
import config from '../../config';
import ServerHealth from './components/ServerHealth';
import MaintenanceMode from './components/MaintenanceMode';
import MobileAppConfiguration from './components/MobileAppConfiguration';
import useAuth from '../../hooks/useAuth';
import { Loader, StatusBar } from '../../components/UI';
import { handleResponse } from '../../api/apiUtils';
import { dashboardAPI } from '../../api/dashboard.api';

function Dashboard() {
	const [appConfig, setAppConfig] = useState({});
  const [loading, setLoading] = useState(true);
  const [showSaved, setShowSaved] = useState(false);
	const { user } = useAuth();

	useEffect(() => {
		if(user){
      setLoading(true);
			dashboardAPI.getAppConfig()
				.then(data => {
					setAppConfig({...data[0]})
				})
				.catch(err => {
          setAppConfig({error: 'Error retrieving config'})
        })
        .finally(() => setLoading(false));
		}
	}, [user])

  const updateConfig = (newConfig) => {
    setAppConfig(appConfig => ({ ...appConfig, ...newConfig}))
  }

	const saveConfig = () => {
    const options = {
      method: appConfig._id ? 'PUT' : 'POST',
      headers: {
        'Authorization':'bearer ' + user.token,
        'Content-Type':'application/json',
      },
      body: JSON.stringify(appConfig)
    };

    setLoading(true);
    
    const ep = appConfig._id ? `${config.SERVER_URL}/config/${appConfig._id}` : `${config.SERVER_URL}/config`

    fetch(ep, options)
      .then(handleResponse)
      .then(data => {
        displaySaved();
      })
      .finally(() => setLoading(false));
	}

  const displaySaved = () => {
    setShowSaved(true)
    setTimeout(() => {
      setShowSaved(false)
    }, 3000)
  }

  const promptMaintenanceMode = (value) => {
    if(value){
      if(confirm('This is going to set the app into maintenance mode. Are you sure you want to continue?')){
        setMaintenanceMode(value);
      }
    } else {
      setMaintenanceMode(value);
    }
  }

	const setMaintenanceMode = (value) => {
    const options = {
      method: 'PUT',
      headers: {
        'Authorization':'bearer ' + user.token,
        'Content-Type':'application/json',
      },
      body: JSON.stringify({
        maintenanceModeEnabled: value,
      })
    };

    fetch(`${config.SERVER_URL}/config/${appConfig._id}/maintenance`, options)
      .then(handleResponse)
      .then(data => {
        setAppConfig(appConfig => ({...appConfig, maintenanceModeEnabled: value}))
      })
      .finally(() => setLoading(false));
  }

  return(
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className={'col-md-12 dashboard row'} >
          {appConfig.error && (
            <StatusBar text={appConfig.error} />
          )}
          <div className={'column'}>
            <MobileAppConfiguration
              configuration={appConfig} 
              updateConfig={updateConfig}
              saveConfig={saveConfig}
              showSaved={showSaved}
            />
          </div>
          <div className={'column'}>
            <ServerHealth />
            <MaintenanceMode 
              configuration={appConfig}
              setMaintenanceMode={promptMaintenanceMode}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Dashboard;
import React from 'react';

const Loader = () => (
  <div className="loaderWrapper">
    <div className="loaderContent">
      <div className="loader" />
    </div>
    
  </div>
)

export default Loader;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addPharmacy, deletePharmacy, getPharmacy, setPharmacy, updatePharmacy } from '../../store/action/pharmacy.actions';
import { Spinner } from 'react-bootstrap'

const initial_pharmacy = {
  name: '',
  address: '',
  address2: '',
  city: '',
  postalCode: '',
  phone: '',
  province: 'Ontario',
  latitude: null,
  longitude: null
}

function Pharmacy() {
  const dispatch = useDispatch();
  const params = useParams();
  const auth = useSelector(state => state.auth)
  const {pharmacy, sending, canSubmit} = useSelector(state => state.pharmacy);
  
  useEffect(() => {
    if(params.id && auth.user){
      dispatch(getPharmacy(params.id))
    } else {
      //new pharmacy, dont do anything
      dispatch(setPharmacy({...initial_pharmacy}))
    }
  }, [params.id, auth.user])

  const handleChange = (name, val) => {
    dispatch(setPharmacy({...pharmacy, [name]:val}))
  }

  const savePharmacy = () => {
    if(pharmacy._id){
      dispatch(updatePharmacy(pharmacy._id))
    } else {
      dispatch(addPharmacy())
    }
  }

  const deleteModel = () => {
    dispatch(deletePharmacy())
  }

  return(
    <div>
      <div className={'col-sm-12 offset-md-1 col-md-10 mt-3'}>
        <div className={'d-flex flex-row justify-content-start'}>
          <h2 className={'p-1 m-1'}>{pharmacy._id ? 'Update Pharmacy' : 'Add New Pharmacy'}</h2>
        </div>
        <hr />

        <div className={'container col-lg-8 col-md-10'}>
          <div className='form-row col-md-12'>
            <div className={'form-group col-md-6'}>
              <label>Pharmacy Name</label>
              <input 
                type='text'
                className='form-control'
                placeholder='Pharmacy Name'
                value={pharmacy.name}
                onChange={e => handleChange('name', e.target.value)}
              />
            </div>
          </div>
          <div className={'form-row col-md-12'}>
            <div className='form-group col-md-6 float-left'>
              <label>Street</label>
              <input
                type='text'
                className={'form-control'}
                placeholder='123 Main Street'
                value={pharmacy.address}
                onChange={e => handleChange('address', e.target.value)}
              />
            </div>
            <div className='form-group col-md-6 float-left'>
              <label>Street</label>
              <input
                type='text'
                className={'form-control'}
                placeholder='Suite 200'
                value={pharmacy.address2}
                onChange={e => handleChange('address2', e.target.value)}
              />
            </div>
          </div>
          <div className={'form-row col-md-12'}>
            <div className='form-group col-md-6 float-left'>
              <label>City</label>
              <input 
                type='text'
                className={'form-control'}
                placeholder='Toronto'
                value={pharmacy.city}
                onChange={e => handleChange('city', e.target.value)}
              />
            </div>
            <div className='form-group col-md-6 float-left'>
              <label>Province</label>
              <select
                className={'form-control'}
                value={pharmacy.province}
                onChange={e => handleChange('province', e.target.value)}
                defaultValue={'Ontario'}
              >
                <option value='Alberta'>Alberta</option>
                <option value='British Columbia'>British Columbia</option>
                <option value='Ontario'>Ontario</option>
                <option value='Manitoba'>Manitoba</option>
                <option value='Newfoundland'>Newfoundland</option>
                <option value='New Brunswich'>New Brunswick</option>
                <option value='Nova Scotia'>Nova Scotia</option>
                <option value='Quebec'>Quebec</option>
                <option value='Saskatchewan'>Saskatchewan</option>
              </select>
            </div>
          </div>
          <div className={'form-row col-md-12'}>
            <div className='form-group col-md-6 float-left'>
              <label>Postal Code</label>
              <input 
                type='text'
                className={'form-control'}
                placeholder='P1P1P1'
                value={pharmacy.postalCode}
                onChange={e => handleChange('postalCode', e.target.value)}
              />
            </div>
            <div className='form-group col-md-6 float-left'>
              <label>Phone Number</label>
              <input
                type='text'
                className={'form-control'}
                placeholder='(123) 456-7890'
                value={pharmacy.phone}
                onChange={e => handleChange('phone', e.target.value)}
              />
            </div>
          </div>
          <div className={'form-row col-md-12 d-flex-row justify-content-between mb-3'}>
            <div className='col-md-6 float-left'>
                <label>Latitude</label>
                <input
                  type='number'
                  className={'form-control'}
                  placeholder='46.5374394'
                  value={pharmacy.latitude}
                  onChange={e => handleChange('latitude', e.target.value)}
                />
            </div>
            <div className='col-md-6 float-right'>
                <label>Longitude</label>
                <input 
                  type='number'
                  className={'form-control'}
                  placeholder='-84.3852966'
                  value={pharmacy.longitude}
                  onChange={e => handleChange('longitude', e.target.value)}
                />
            </div>
          </div>
          <div className={'d-flex-row justify-content-between col-md-12'}>
            <button type='button' className={'btn btn-outline-primary float-left'} onClick={savePharmacy} disabled={!canSubmit}>
            {sending && <Spinner animation="border" size='sm'/>}
            {pharmacy._id ? ' Update Pharmacy ' : ' Add Pharmacy '}
            </button>
            {pharmacy._id && (
              <button type='button' className={'btn btn-outline-danger float-right'} onClick={deleteModel}>
                Remove Pharmacy
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pharmacy;
import React, { useState, useEffect } from 'react';
import ToggleSwitch from '../../../components/UI/ToggleSwitch/ToggleSwitch';

const enable = 'Enable maintenance mode';
const disable = 'Disable maintenance mode';

const MaintenanceMode = ({ configuration, setMaintenanceMode }) => {
	const [maintenanceText, setMaintenanceText] = useState(enable)
	
	useEffect(() => {
		if(configuration.maintenanceModeEnabled){
			setMaintenanceText(disable)
		} else {
			setMaintenanceText(enable)
		}
	}, [configuration]);
	
  const { maintenanceModeEnabled } = configuration;
	return (
		<div className='col-sm-12 sectionContainer'>
			<h4>Maintenance Mode</h4>
			<div className={'statusRow'}>
				<p className='label'>{maintenanceText}</p>
        <ToggleSwitch
          name={'maintenanceMode'} 
          value={maintenanceModeEnabled} 
          setValue={setMaintenanceMode}
        />
			</div>
			<div className={'statusRow center'}>
				<p className={`maintenanceModeText ${maintenanceModeEnabled ? 'active' : 'inactive'}`}>
          * {maintenanceModeEnabled ? 'Maintenance mode active' : 'Maintenance mode inactive'} *
        </p>
			</div>
		</div>
	)
}

export default MaintenanceMode;

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'; 
import { authActions } from '../../store/action/auth.actions';
import { useHistory } from 'react-router-dom';
import { useInput } from '../../hooks';

function Login() {
  const { value:email, bind:bindEmail } = useInput('');
  const { value:password, bind:bindPassword } = useInput('');
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  function doLogin() {
    dispatch(authActions.login(email, password)) 
	}

  useEffect(() => {
		if(auth.isLoggedIn) {
      history.push('/dashboard')
    }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth.isLoggedIn]);
	
	

  return(
    <div className={'container'}>
      <div className={'card col-sm-12 offset-md-2 col-md-8 offset-lg-4 col-lg-4 pr-0 pl-0 mt-3'}>
        <div className={'card-header'}>
          <h4>Login</h4>
        </div>
        <div className={'card-body'}>
            <div className={'form-group'}>
              <input type={'text'} className={'form-control'} placeholder={'name@example.com'} disabled={auth.loggingIn} {...bindEmail}/>
            </div>
            <div className={'form-group'}>
              <input type={'password'} className={'form-control'} placeholder={'password'} disabled={auth.loggingIn} {...bindPassword}/>
            </div>
            <button className={'btn btn-primary'} type={'submit'} onClick={() => doLogin()} disabled={auth.loggingIn}>Login</button>
        </div>
        {auth.loginError && (
          <p>{auth.loginError.toString()}</p>
        )}
      </div>
    </div>
  )
}

export default Login;
import React from 'react';
import ConfigurableToggleBlock from './ConfigurableToggleBlock';

const MobileAppConfiguration = ({ configuration, updateConfig, saveConfig, showSaved }) => {
	return (
		<div className={'col-sm-12 sectionContainer'}>
			<h4>Mobile Configuration</h4>
      <ConfigurableToggleBlock
        label="Nasal Kit Available"
        objectKey="nasalSprayAvailable"
        configObject={configuration}
        updateConfig={updateConfig}
        hasMessage
      />
      <ConfigurableToggleBlock
        label="Injection Kit Available"
        objectKey="injectableAvailable"
        configObject={configuration}
        updateConfig={updateConfig}
        hasMessage
      />
      <div className={'statusRow center mv5'}>
        <button type="button" className="saveBtn" onClick={saveConfig}>
          <span className="saveBtnText">Save Configuration</span>
        </button>
      </div>
      {showSaved && (
        <span className={'statusRow center'}>Configuration Saved Successfully</span>
      )}
		</div>
	)	
}

export default MobileAppConfiguration;
/* eslint-disable no-unused-vars */
import config from "../config";
import { handleResponse } from "./apiUtils";

export const dashboardAPI = {
 getAppConfig, 
 setAppConfig,
}

function getAppConfig() {
  return new Promise((resolve, reject) => {
    fetch(`${config.SERVER_URL}/config`, {
      method: 'GET',
      headers: {
        "Content-Type":"application/json",
      },
    })
      .then(handleResponse)
      .then(
        data => {
          resolve(data)
        },
        error => {
          reject(error)
        }
      );
  })
}

function setAppConfig(newConfiguration) {
  const user = {};
  return new Promise((resolve, reject) => {
    const options = {
      method: newConfiguration._id ? 'PUT' : 'POST',
      headers: {
        'Authorization':'bearer ' + user.token,
        'Content-Type':'application/json',
      },
      body: JSON.stringify(newConfiguration)
    };

    const ep = newConfiguration._id ? `${config.SERVER_URL}/config/${newConfiguration._id}` : `${config.SERVER_URL}/config`
  })
}
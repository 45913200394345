import React from 'react';

const UserForm = ({ user, setUser, updateUser, disabled }) => {
  return (
    <div>
      <div className='form-group'>
        <label for='firstname'>Username</label>
        <input 
          type='text' 
          className='form-control' 
          placeholder='name@example.com'
          value={user.email}
          onChange={(e) => setUser({...user, email: e.target.value})}
        />
      </div>
      <div className='form-group'>
        <label for='firstname'>First Name</label>
        <input
          type='text'
          className='form-control'
          placeholder='John'
          value={user.firstname}
          onChange={(e) => setUser({...user, firstname: e.target.value})}
        />
      </div>
      <div className='form-group'>
        <label for='lastname'>Last Name</label>
        <input
          type='text'
          className='form-control'
          placeholder='Smith'
          value={user.lastname}
          onChange={(e) => setUser({...user, lastname: e.target.value})}
        />
      </div>
      <button type='button' className={'btn btn-outline-primary'} onClick={updateUser} disabled={disabled}>
        Save
      </button>
    </div>
  )
}

export default UserForm;